import React, { lazy, Suspense } from "react";
import { Switch, Route } from "react-router-dom";

import Footer from "../components/Footer";
import Header from "../components/Header";

import routes from "./config";
import GlobalStyles from "../globalStyles";

function redirectTo({match}) {
  // console.log("ydebug", match.params.subPart);
  window.location.replace(`https://dashboard.linesale.in/home/${match.params.subPart}`)
  return true;
}

const Router = () => {
  return (
    <Suspense fallback={null}>
      <GlobalStyles />
      <Header />
      <Switch>
        {routes.map((routeItem) => {
          return (
            <Route
              key={routeItem.component}
              path={routeItem.path}
              exact={routeItem.exact}
              component={lazy(() => import(`../pages/${routeItem.component}`))}
            />
          );
        })}
        <Route path="/dashboard/:subPart" component={redirectTo} />
      </Switch>
      <Footer />
    </Suspense>
  );
};

export default Router;
