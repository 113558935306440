import { useState, Fragment, lazy } from "react";
// import { Link, Redirect } from "react-router-dom";
import { Link} from "react-router-dom";

import { Row, Col, Drawer } from "antd";
import { CSSTransition } from "react-transition-group";
import { withTranslation } from "react-i18next";
import React from "react";

import * as S from "./styles";

// const SvgIcon = lazy(() => import("../../common/SvgIcon"));
const Button = lazy(() => import("../../common/Button"));

const Header = ({ t }) => {
  const [isNavVisible] = useState(false);
  const [isSmallScreen] = useState(false);
  const [visible, setVisibility] = useState(false);

  const showDrawer = () => {
    setVisibility(!visible);
  };

  const onClose = () => {
    setVisibility(!visible);
  };

  const MenuItem = () => {
    return (
      <Fragment>
        <Link to="/">
          <S.CustomNavLinkSmall>
            <S.Span>{t("Home")}</S.Span>
          </S.CustomNavLinkSmall>
        </Link>

        <S.CustomNavLinkSmall>
          <a href="/#about">
            <S.Span>{t("About")}</S.Span>
          </a>
        </S.CustomNavLinkSmall>

        <Link to="/userguide">
          <S.CustomNavLinkSmall>
            <S.Span>{t("User Guide")}</S.Span>
          </S.CustomNavLinkSmall>
        </Link>

        <S.CustomNavLinkSmall>
          <a href="/#refund">
            <S.Span>{t("Refund Policy")}</S.Span>
          </a>
        </S.CustomNavLinkSmall>

        <S.CustomNavLinkSmall>
          <a href="/#contact">
            <S.Span>{t("Contact")}</S.Span>
          </a>
        </S.CustomNavLinkSmall>

        <S.CustomNavLinkSmall style={{ width: "0px" }}>
          <a href="https://dashboard.linesale.in">
            <Button height={"true"} width={"true"} backgroundColor="#e4528d">
              {t("Login")}
            </Button>
          </a>
        </S.CustomNavLinkSmall>

      </Fragment>
    );
  };

  return (
    <S.Header>
      <S.Container>
        <Row type="flex" justify="space-between" gutter={20} align="middle">
          <S.LogoContainer to="/" aria-label="homepage">
            <S.Image src={"/img/png/applogo.png"} />
            <S.SpanTitle color="#FB0065" style={{ marginLeft: "15px" }}>
              {t("Line Sale")}
            </S.SpanTitle>
          </S.LogoContainer>
          <S.NotHidden>
            <MenuItem />
          </S.NotHidden>
          <S.Burger onClick={showDrawer}>
            <S.Outline />
          </S.Burger>
        </Row>
        <CSSTransition
          in={!isSmallScreen || isNavVisible}
          timeout={350}
          classNames="NavAnimation"
          unmountOnExit
        >
          <Drawer
            closable={false}
            visible={visible}
            onClose={onClose}
            width="80%"
          >
            <S.Col style={{ marginBottom: "2.5rem" }}>
              <S.Label onClick={onClose}>
                <Col span={12}>
                  <S.Menu>Menu</S.Menu>
                </Col>
                <Col span={12}>
                  <S.Outline padding="true" />
                </Col>
              </S.Label>
              <MenuItem />
            </S.Col>
          </Drawer>
        </CSSTransition>
      </S.Container>
    </S.Header>
  );
};

export default withTranslation()(Header);
