import styled from "styled-components";
import { Link } from "react-router-dom";
import { MenuOutlined } from "@ant-design/icons";

export const Header = styled.header`
  padding: 1.4rem 0.5rem;
  width: 100%;
  background-color: #ffffff;
  top: 0;
  overflow: hidden;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  height: 115px;
  position: relative;
  z-index: 10;

  @media only screen and (max-width: 870px) {
    padding: 1.4rem 0rem;
  }
`;

export const LogoContainer = styled(Link)`
  display: flex;
  margin-right: auto;
  align-items: center;
  
`;

export const Container = styled.div`
  position: relative;
  width: 100%;
  padding: 0 5rem;
  margin-right: auto;
  margin-left: auto;
  align-items: center;
  justify-content: center;
  
  @media only screen and (max-width: 845px) {
    padding: 0 1.5rem;
  }
`;

export const NavLink = styled.div`
  display: inline-block;
  text-align: center;
  justify-content: center;
`;

export const CustomNavLink = styled.div`
  width: 203px;
  display: inline-block;

  @media only screen and (max-width: 411px) {
    width: 150px;
  }

  @media only screen and (max-width: 320px) {
    width: 118px;
  }
`;

export const ContactWrapper = styled.div`
  cursor: pointer;
  width: ${(props) => (props.width ? "100%" : "110px")};
  font-weight: 700;
  text-align: center;
  border-radius: 1.25rem;
  display: inline-block;
`;

export const Burger = styled.div`
  @media only screen and (max-width: 789px) {
    display: block !important;
  }
  padding: 1.25rem 1.25rem;
  display: none;
`;

export const NotHidden = styled.div`
  padding-right: 50px;
  @media only screen and (max-width: 845px) {
    padding-right: 100px;
  }
  @media only screen and (max-width: 789px) {
    display: none;
  }
`;

export const Menu = styled.h5`
  font-size: 1.37rem;
  margin-top: -0.45rem;
  padding: 0 1.56rem 0 0;
  font-weight: 600;
  border-bottom: 5px solid #111b47;
`;

export const CustomNavLinkSmall = styled(NavLink)`
  font-size: 1rem;
  font-weight: 500;
  color: #000000;
  transition: color 0.2s ease-in;
  margin: 0.25rem 0.7rem;

  &:hover {
    color: #fb0065;
  }

  @media only screen and (max-width: 789px) {
    margin: 1.25rem 2rem;
  }
`;

export const Label = styled.span`
  font-size: 12px;
  font-weight: 500;
  color: #404041;
  text-align: right;
  display: flex;
  width: 100%;
`;

export const Col = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
`;

export const Outline = styled(MenuOutlined)`
  font-size: 22px;
  padding-right: ${(props) => (props.padding ? "10px" : "")};
`;

export const Span = styled.span`
  cursor: pointer;
  font-size: 1rem;
  font-family: "Maitree", sans-serif !important;
  font-weight: 600;
`;

export const SpanTitle = styled.span`
  cursor: pointer;
  font-size: 1.5rem;
  font-family: "montserrat";
  font-weight: 700;
  color: ${(props) => (props.color ? props.color : "black")};
  letter-spacing: 0.5px;
`;

export const Image = styled.img`
  height: 60px;

  @media only screen and (max-width: 789px) {
    height: 40px;
  }
`;
